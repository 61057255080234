/* fonts */
@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('/public/fonts/ITC Avant Garde Gothic LT Extra Light.ttf');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('/public/fonts/ITC Avant Garde Gothic LT Bold.otf');
  font-weight: 600;
  font-style: bold;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('/public/fonts/ITC Avant Garde Gothic Book Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ITC Avant Garde Gothic';
  src: url('/public/fonts/ITC Avant Garde Gothic Oblique.ttf');
  font-weight: 400;
  font-style: italic;
}

/* style */
body,
html,
#root {
  font-family: 'ITC Avant Garde Gothic';
}

.bg-light {
  background-image: url(/public/images/lockersBack.png);
  background-repeat: repeat-y;
  height: 100vh !important;
}

.pt-3,
.py-3 {
  padding-top: 2rem !important;
  padding-bottom: 10rem !important;
}

.modal-title {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

h1,
h5,
h6 {
  font-weight: bold;
  line-height: 1.5;
}

label {
  font-weight: bold;
}

.rounded h6 {
  color: #00567d;
}

.bg-login {
  background: url('/public/images/login_bg.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh !important;
}

.bg-main {
  background: linear-gradient(45deg, #FFD800, #66cdcc);
}

.bg-vw-red {
  background-color: #d60000;
}

.bg-vw-purple {
  background-color: #563d7c;
}

.bg-vw-dark {
  background-color: #6f6f6f;
}

.bg-vw-darkest {
  background-color: #6f6f6f;
}

.bg-vw-light {
  background-color: rgb(45, 204, 211);
}

.bg-vw-light-fat {
  height: 5px !important;
  background-color: #00567d !important;
}

.bg-vw-yellow {
  background-color: #fc9700;
}

.bg-vw-excel {
  background-color: #297148;
}

.text-vw-dark {
  color: rgb(0, 90, 125);
}

.text-vw-light {
  color: rgb(45, 204, 211);
}

.text-vw-green {
  color: #2dd334;
}

.text-vw-red {
  color: #ff0000;
}

.logo {
  height: 40px;
}

.hover:hover {
  cursor: pointer;
  color: #fc9700;
}

.table {
  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.no-selectable {
  -webkit-user-select: none; /* Chrome, Safari */
  -moz-user-select: none;    /* Firefox */
  -ms-user-select: none;     /* Internet Explorer/Edge */
  user-select: none;         /* Standard */
}

.table-clickable tr {
  cursor: pointer;
  background-color: #fff;
}

.table-clickable tr:hover {
  background-color: #2dccd3 !important;
  color: #fff !important;
}

th,
td {
  text-align: center !important;
  vertical-align: middle !important;
  width: 100px;
}

.thead-dark th {
  background-color: #44484d !important;
}

th input,
select {
  border: 2px solid #fc9700;
  border-radius: 5px;
  text-align: center;
}

.thead-manual {
  background-color: #fdc5c5 !important;
  font-size: 9pt;
}

.thead-clients {
  background-color: #c5dbfd !important;
}

.thead-totals {
  background-color: #ffb100c4 !important;
}

.list-undecorated {
  list-style-type: none;
  padding: 0;
}

.text-break {
  white-space: pre-line;
  word-break: break-all;
}

.btn-multi-line {
  white-space: normal;
  /* Allows text wrapping */
  line-height: 1.5;
  /* Adjusts line spacing for wrapped text */
  height: auto;
  /* Adjusts height automatically based on content */
  padding: 0.5rem 1rem;
  /* Optional: Adjust padding to ensure text fits well */
}

.btn {
  box-shadow: 2px 2px 2px #6b6b6b;
  cursor: pointer;
}

.btn:hover {
  box-shadow: none;
}

@media (max-width: 768px) {
  .btn-multi-line {
    margin-top: 5px;
  }

  .btn-group {
    flex-direction: column;
    /* Stack buttons vertically */
    width: 100%;
    /* Full width to use all available space */
  }
}

.text-monospace {
  font-family: monospace;
}

.box-shadow {
  /*box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);*/
  box-shadow: 0.5rem 0.5rem 1rem rgb(53 172 191 / 25%);
}

.box-iconed {
  min-height: 100px;
  text-align: center;
}

.box-border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

.ct {
  text-align: center;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
}

.badge {
  color: #ffffff;
}

.save {
  position: fixed;
  bottom: 0;
  width: 10vw;
  height: 3vw;
  font-size: 20px;
  left: 45%;
  transition: all 0.3s ease;
  /* Smooth transition for hover effect */
  background-color: #2dccd3;
  /* Change to your preferred background color */
  color: white;
  border: none;
  border-radius: 5px;
  /* Rounded corners for a sleek look */
}

.save:hover {
  transform: translateY(-5px);
  /* Slightly raise the button */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Add shadow for 3D effect */
  background-color: #00567d;
  /* Slightly darken the background color on hover */
  cursor: pointer;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.rotating svg {
  animation: rotate 2s linear infinite;
}

@media print {
  .no_print {
    display: none;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 90vw !important;
  }
}

@media (max-width: 1200px) {
  .save {
    position: fixed;
    bottom: 0;
    width: 30vw;
    height: 15vw;
    font-size: 20px;
    left: 35%;
  }
}

.salesCompute th {
  width: 2vw;
}

.topButton {
  width: 50px;
  height: 50px;
  border: 2px solid #2dccd3;
  background-color: #2b2b2b;
  color: #fff;
  border-radius: 90px;
  -webkit-box-shadow: 0.5rem 0.5rem 1rem rgb(53 172 191/25%);
  box-shadow: 0.5rem 0.5rem 1rem rgb(53 172 191/25%);
  position: fixed;
  bottom: 25px;
  right: 25px;
  text-align: center;
  cursor: pointer;
  font-size: xx-large;
  z-index: 10;
}

.topButton:hover {
  background-color: #979797;
}

.topButton:active {
  background-color: #2b2b2b;
}

.chatButton {
  width: 50px;
  height: 50px;
  border: 2px solid #49d32d;
  background-color: #2b2b2b;
  color: #fff;
  border-radius: 90px;
  -webkit-box-shadow: 0.5rem 0.5rem 1rem rgb(53 172 191/25%);
  box-shadow: 0.5rem 0.5rem 1rem rgb(53 172 191/25%);
  position: fixed;
  bottom: 100px;
  right: 25px;
  text-align: center;
  cursor: pointer;
  font-size: x-large;
  z-index: 10;
}

.chatButton:hover {
  background-color: #979797;
}

.chatButton:active {
  background-color: #2b2b2b;
}

/*---------------------LOCKERS GUI---------------------*/

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff !important;
  background-color: #2dccd3 !important;
}

.lockerBlocked {
  opacity: 0.2;
}

/* This will prevent the hover effect if the .blocked class is present */
.lockerBlocked:hover {
  cursor: not-allowed !important; /* Optionally change the cursor to indicate the locker is blocked */
}

/* S */

#lockerBNS {
  width: 45px;
  height: 45px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBNS:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBNS p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCNS {
  width: 45px;
  height: 45px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCNS:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCNS p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerBWS {
  width: 60px;
  height: 45px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBWS:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBWS p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCWS {
  width: 60px;
  height: 45px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCWS:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCWS p {
  font-size: 12pt;
  color: #ffffff;
}

/* M */

#lockerBNM {
  width: 45px;
  height: 90px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBNM:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBNM p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCNM {
  width: 45px;
  height: 90px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCNM:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCNM p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerBWM {
  width: 60px;
  height: 90px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBWM:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBWM p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCWM {
  width: 60px;
  height: 90px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCWM:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCWM p {
  font-size: 12pt;
  color: #ffffff;
}

/* L */

#lockerBNL {
  width: 45px;
  height: 135px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBNL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBNL p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCNL {
  width: 45px;
  height: 135px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCNL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCNL p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerBWL {
  width: 60px;
  height: 135px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBWL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBWL p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCWL {
  width: 60px;
  height: 135px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCWL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCWL p {
  font-size: 12pt;
  color: #ffffff;
}

/* XL */

#lockerBNXL {
  width: 45px;
  height: 180px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBNXL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBNXL p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCNXL {
  width: 45px;
  height: 180px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCNXL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCNXL p {
  font-size: 12pt;
  color: #ffffff;
}

/* 2XL */

#lockerBN2XL {
  width: 45px;
  height: 225px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBN2XL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBN2XL p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCN2XL {
  width: 45px;
  height: 225px;
  text-align: center;
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 139, 201, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCN2XL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCN2XL p {
  font-size: 12pt;
  color: #ffffff;
}

/* 3XL */

#lockerBN3XL {
  width: 45px;
  height: 270px;
  text-align: center;
  background: rgb(112, 112, 112);
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
  cursor: pointer;
  border: 1px solid #004f75;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBN3XL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBN3XL p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerCN3XL {
  width: 45px;
  height: 270px;
  text-align: center;
  background: rgb(125, 8, 0);
  background: linear-gradient(180deg, rgba(125, 8, 0, 1) 0%, rgb(201, 10, 3) 100%);
  cursor: pointer;
  border: 1px solid #810101;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerCN3XL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerCN3XL p {
  font-size: 12pt;
  color: #ffffff;
}

#lockerBW3XL {
  width: 60px;
  height: 270px;
  text-align: center;
  background: rgb(3, 3, 3);
  background: linear-gradient(180deg, rgba(3, 3, 3, 1) 0%, rgb(43, 43, 43) 100%);
  cursor: pointer;
  border: 1px solid #000000;
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerBW3XL:hover {
  background: rgb(0, 86, 125);
  background: linear-gradient(180deg, rgba(0, 86, 125, 1) 0%, rgba(3, 201, 186, 1) 100%);
}

#lockerBW3XL p {
  font-size: 12pt;
  color: #ffffff;
}

/* Kiosc */

#lockerEmpty {
  width: 44px;
  height: 44px;
  text-align: center;
  background: rgba(0, 255, 255, 0);
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
}

#lockerKioskLeft {
  width: 45px;
  height: 135px;
  margin-right: 0px;
  text-align: center;
  background: rgba(0, 255, 255, 0);
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
  background: url(/public/images/kiosk_left.png);
  background-size: cover;
}

#lockerKioskRight {
  width: 45px;
  height: 135px;
  margin-left: 0px;
  text-align: center;
  background: rgba(0, 255, 255, 0);
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
  background: url(/public/images/kiosk_right.png);
  background-size: cover;
}

#lockerEcomKioskLeft {
  width: 45px;
  height: 90px;
  margin-right: 0px;
  text-align: center;
  background: rgba(0, 255, 255, 0);
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
  background: url(/public/images/kiosk_ecom_left.png);
  background-size: cover;
}

#lockerEcomKioskRight {
  width: 45px;
  height: 90px;
  margin-left: 0px;
  text-align: center;
  background: rgba(0, 255, 255, 0);
  display: inline-block;
  flex-wrap: wrap;
  flex-direction: column;
  z-index: 2;
  background: url(/public/images/kiosk_ecom_right.png);
  background-size: cover;
}

/* STATUS */

#AV {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #35f734;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#AV p {
  opacity: 0;
}

#FB {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4a7aff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#FB p {
  opacity: 0;
}

#FL {
  width: 100%;
  height: 100%;
  top: -40px;
  position: relative;
  background: url(/public/images/cross.png);
  background-size: 100% 100%;
  z-index: -1;
}

#FL p {
  opacity: 0;
}

#RK {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#RK p {
  opacity: 0;
}

#RM {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#RM p {
  opacity: 1;
  font-size: 8pt;
  line-height: 8pt;
}

#RQ {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#RQ p {
  opacity: 1;
  font-size: 8pt;
  line-height: 8pt;
}

#RP {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#RP p {
  opacity: 1;
  font-size: 8pt;
  line-height: 8pt;
}

#RA {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#RA p {
  opacity: 1;
  font-size: 8pt;
  line-height: 8pt;
}

#BL {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #b500fc;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#BL p {
  opacity: 0;
}

#BM {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #f8fc00;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#BM p {
  opacity: 1;
  font-size: 8pt;
  line-height: 8pt;
  color: #000;
}

#Null {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #0e0e0e;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#Null p {
  opacity: 0;
}

/* RSSI SIGNAL STATUS */

#WHITE {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #ffffff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#WHITE p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#LOWTOP {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #b74aff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#LOWTOP p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#LOW {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4d4aff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#LOW p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#LOWHIGH {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4a83ff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#LOWHIGH p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#MIDLOW {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4accff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#MIDLOW p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#MID {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4affba;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#MID p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#MIDHIGH {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #6eff4a;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#MIDHIGH p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#HIGHLOW {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #ffed4a;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#HIGHLOW p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#HIGH {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #ffa14a;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#HIGH p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#HIGHTOP {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #ff504a;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 15px;
  position: relative;
}

#HIGHTOP p {
  opacity: 1;
  font-size: 10pt;
  line-height: 10pt;
  margin: 0px !important;
  padding: 0px !important;
  color: #000;
}

#lockerMatrix {
  height: 280px;
  flex-direction: column;
  align-content: flex-start;
  margin-left: 1vw;
}

#indicatorNull {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #0e0e0e;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorAvailable {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #35f734;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorRented {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #fc9700;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorError {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #35f734;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorErrorAdmin {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #4a7aff;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#indicatorErrorBattery {
  width: 30px;
  height: 15px;
  align-self: center;
  background-color: #dd0202;
  border: 2px solid #4a4a4a;
  border-radius: 90px;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  position: relative;
}

#kiosk {
  background: url(/public/images/machines/big.png);
  background-size: cover;
  width: 126px;
  height: 277px;
  margin-top: 0;
  position: absolute;
  margin-left: 489px;
  margin-right: auto;
  z-index: 1;
}

#matrixContainer {
  overflow: auto;
  margin: 0 auto;
  display: table;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media (max-width: 1200px) {
  #matrixContainer {
    overflow: auto;
    margin: 0 auto;
    display: table;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5vw;
    padding-right: 5vw;
    position: relative;
  }
}

#matrixScroll {
  overflow-x: scroll;
}

#matrixScroll::-webkit-scrollbar {
  width: 8px;
  /* width of the entire scrollbar */
}

#matrixScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* color of the tracking area */
}

#matrixScroll::-webkit-scrollbar-thumb {
  background-color: #888;
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: none;
  /* removes the border around the thumb */
}

#matrixScroll::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* color of the scroll thumb when hovering */
}

#totalMatrixContainer {
  margin-left: auto;
  margin-right: auto;
  /* border: 2px solid #2dccd3; */
  padding: 1vw 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.totalMatrixIndicators {
  text-align: center;
  padding: 10px 30px 10px 30px;
  /* padding-right: 1vw; */
  border-left: 2px solid #00567d;
  border-right: 2px solid #00567d;
  border: 2px solid #2dccd3;
  margin: 10px;
  background-color: #00567d;
  color: #ffffff;
  border-radius: 10px;
}

.complete-width {
  width: 100%;
  margin-left: 15px;
  margin-right: 15px;
}

.form-group {
  min-width: 100px !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem !important;
  margin-top: 0 !important;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.button-center {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-top: 1vw;
}

/* ANIMACIÓN DE CARGA DE TABLAS */


.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 150px;
  height: 150px;
  background-color: #2dccd3;
  border-radius: 50%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.spinner img {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
  animation: spin 1s linear infinite;
  z-index: 1;
}

.spinner::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #ccc;
  border-right-color: #00567d;
  border-left-color: #00567d;
  animation: spin 1s linear infinite;
  z-index: 0;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* .spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border-left-color: #09f;

  animation: spin 1s ease infinite;
  margin-left: auto;
  margin-right: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
} */

/* PROMOTERS TABLE */

.timetable h6 {
  margin: 5px;
  width: 100px;
  margin-left: 15px;
}

.timetable label {
  margin: 5px;
  width: 100px;
  text-align: center;
}

.timetable .form-group {
  text-align: center !important;
  max-width: 100px;
  margin-left: 5px;
}

/* MANUAL EXPEDITIONS HOME */

.bg-light-blue {
  background-color: #2dccd3 !important;
}

.bg-light-blue h5 {
  color: #ffffff !important;
}

.bg-light-blue h6 {
  color: #ffffff !important;
}

.bg-light-blue p {
  color: #ffffff !important;
}

.bg-light-blue .text-muted {
  color: #ffffff !important;
}

.bg-light-blue .bg-vw-light {
  background-color: #ffffff !important;
}


/* TEST 404 STARNGER THINGS*/

.c {
  text-align: center;
  display: block;
  position: relative;
  width: 80%;
  margin: 100px auto;
}

._404 {
  font-size: 220px;
  position: relative;
  display: inline-block;
  z-index: 2;
  height: 250px;
  letter-spacing: 15px;
}

._1 {
  text-align: center;
  display: block;
  position: relative;
  letter-spacing: 12px;
  font-size: 4em;
  line-height: 80%;
}

._2 {
  text-align: center;
  display: block;
  position: relative;
  font-size: 20px;
}

.text {
  font-size: 70px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 19px 0px 0px 0px;
  /* top: 256.301px; */
  z-index: 3;
  width: 100%;
  line-height: 1.2em;
  display: inline-block;
}

.right {
  float: right;
  width: 60%;
}

.cloud {
  width: 350px;
  height: 120px;

  background: #2dccd3;
  background: linear-gradient(to top, #2dccd3 100%);
  background: -webkit-linear-gradient(to top, #2dccd3 100%);
  background: -moz-linear-gradient(to top, #2dccd3 100%);
  background: -ms-linear-gradient(to top, #2dccd3 100%);
  background: -o-linear-gradient(to top, #2dccd3 100%);

  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;

  position: absolute;
  margin: 120px auto 20px;
  z-index: -1;
  transition: ease 1s;
}

.cloud:after,
.cloud:before {
  content: '';
  position: absolute;
  background: #2dccd3;
  z-index: -1
}

.cloud:after {
  width: 100px;
  height: 100px;
  top: -50px;
  left: 50px;

  border-radius: 100px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
}

.cloud:before {
  width: 180px;
  height: 180px;
  top: -90px;
  right: 50px;

  border-radius: 200px;
  -webkit-border-radius: 200px;
  -moz-border-radius: 200px;
}

.x1 {
  top: -50px;
  left: 100px;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
  opacity: 0.9;
  animation: moveclouds 15s linear infinite;
  -webkit-animation: moveclouds 15s linear infinite;
  -moz-animation: moveclouds 15s linear infinite;
  -o-animation: moveclouds 15s linear infinite;
}

.x1_5 {
  top: -80px;
  left: 250px;
  -webkit-transform: scale(0.3);
  -moz-transform: scale(0.3);
  transform: scale(0.3);
  animation: moveclouds 17s linear infinite;
  -webkit-animation: moveclouds 17s linear infinite;
  -moz-animation: moveclouds 17s linear infinite;
  -o-animation: moveclouds 17s linear infinite;
}

.x2 {
  left: 250px;
  top: 30px;
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.6;
  animation: moveclouds 25s linear infinite;
  -webkit-animation: moveclouds 25s linear infinite;
  -moz-animation: moveclouds 25s linear infinite;
  -o-animation: moveclouds 25s linear infinite;
}

.x3 {
  left: 250px;
  bottom: -70px;

  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
  transform: scale(0.6);
  opacity: 0.8;
  animation: moveclouds 25s linear infinite;
  -webkit-animation: moveclouds 25s linear infinite;
  -moz-animation: moveclouds 25s linear infinite;
  -o-animation: moveclouds 25s linear infinite;
}

.x4 {
  left: 470px;
  bottom: 20px;

  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform: scale(0.75);
  opacity: 0.75;
  animation: moveclouds 18s linear infinite;
  -webkit-animation: moveclouds 18s linear infinite;
  -moz-animation: moveclouds 18s linear infinite;
  -o-animation: moveclouds 18s linear infinite;
}

.x5 {
  left: 200px;
  top: 300px;

  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  transform: scale(0.5);
  opacity: 0.8;
  animation: moveclouds 20s linear infinite;
  -webkit-animation: moveclouds 20s linear infinite;
  -moz-animation: moveclouds 20s linear infinite;
  -o-animation: moveclouds 20s linear infinite;
}

@media (max-width: 768px) {
  .c {
    text-align: center;
    display: block;
    position: relative;
    width: 80%;
    margin: 20px auto;
  }

  ._404 {
    font-size: 100px;
    position: relative;
    display: inline-block;
    z-index: 2;
    height: 0px;
    letter-spacing: 15px;
  }

  ._1 {
    text-align: center;
    display: block;
    position: relative;
    letter-spacing: 12px;
    font-size: 4em;
    line-height: 130%;
  }

  ._2 {
    text-align: center;
    display: block;
    position: relative;
    font-size: 4em;
  }
}

@keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }

  100% {
    margin-left: -1000px;
  }
}

@-webkit-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }

  100% {
    margin-left: -1000px;
  }
}

@-moz-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }

  100% {
    margin-left: -1000px;
  }
}

@-o-keyframes moveclouds {
  0% {
    margin-left: 1000px;
  }

  100% {
    margin-left: -1000px;
  }
}

/*RESPONSIVE*/

@media (max-width: 1200px) {

  td,
  th {
    font-size: 8px;
    width: auto;
  }

  .complete-width {
    width: auto !important;
    margin-right: 0;
    margin-left: 0;
  }
}

/*PAGINATION INDEX*/

#page-numbers {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  max-width: 100vw;
  flex-wrap: wrap;
  padding: 10px;
}

#page-numbers li {
  padding: 5px 10px;
  border: 1px solid #2dccd3;
  color: #00567d;
  list-style-type: none;
  margin: 10px;
  border-radius: 5px;
}

#page-numbers li.active {
  color: #ffffff;
  cursor: pointer;
  background-color: #00567d;
}

#page-numbers li:hover {
  color: #ffffff;
  cursor: pointer;
  background-color: #00567d;
}

/*    RESUME CONTAINER    */

.resumeContainer {
  display: flex;
  text-align: center;
}

.resumeItem {
  width: 300px;
  height: auto;
  border: 5px solid #c5c5c5;
  border-radius: 20px;
  margin: auto;
  padding: 10px;
  text-align: center;
}

.resumeItem h4 {
  font-weight: bold;
}

/* TICKET */

.lockerTicket {
  border-style: dashed;
  padding: 25px;
  margin-bottom: 25px;
  border-color: #00567d;
}

.lockTitle {
  text-align: center;
}

.lockTitleJustify {
  justify-content: space-between;
  display: flex;
}

.full {
  width: 100%;
  text-align: center;
}


/* LOCKER SALE */

.cube {
  width: 120px;
  height: auto;
  border: 1px solid #2dccd3;
  border-radius: 20px;
  cursor: pointer;
  padding: 20px;
  display: block;
  margin: 20px;
  text-align: center;
  box-shadow: 0.5rem 0.5rem 1rem rgb(53 172 191 / 25%);
  min-height: 200px;
}

.cubeselected {
  width: 120px;
  height: auto;
  border: 1px solid #2dccd3;
  border-radius: 20px;
  cursor: pointer;
  padding: 20px;
  display: block;
  margin: 20px;
  text-align: center;
  background-color: #00567d;
  color: #ffffff;
  box-shadow: 0.5rem 0.5rem 0rem rgba(0, 217, 255, 0.815);
  min-height: 200px;
}

.cube:hover {
  background-color: #00567d;
  color: #ffffff;
  box-shadow: 0.5rem 0.5rem 1rem rgba(255, 196, 0, 0.25);
}

.cube-price {
  border: 1px solid #2dccd3;
  border-radius: 10px;
  padding: 5px;
  position: relative;
  background-color: #00567d;
}

.machinelistlanguage {
  width: 25px;
  height: 25px;
}

.input-container {
  justify-content: center;
  /* Center horizontally, remove if not needed */
  align-items: center;
  /* Center vertically */
  height: 100%;
  /* Adjust as needed, ensure it's enough to demonstrate vertical centering */
}

.countdownTPV {
  background-color: #00567d;
  border-radius: 10px;
  bottom: 20px;
  left: -20px;
  color: #fff;
  /* position: fixed; */
  padding: 10px 20px;
  text-align: center;
  font-weight: bold;
  line-height: 10px;
  border: 2px solid #2dccd3;
}

.lockerSaleButtons {
  position: fixed;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

@media (max-width: 600px) {
  .lockerSaleButtons {
    position: fixed;
    bottom: 25px;
    left: 34vw;
    z-index: 10;
  }
}


/* SALES CHART */

#canvas-container {
  padding-top: 3vw;
  padding-bottom: 3vw;
  z-index: 10;
  position: relative;
  max-height: 60vh;
  background-color: #fff;
}

/* SALE LIST LOCKER PEGATINA */

.lockerID {
  width: auto;
  height: auto;
  font-weight: 700;
  color: #fff;
  background-color: #000;
  border-radius: 10px;
  line-height: 30px;
  margin-left: auto;
  margin-right: auto;
}

.lockerIDTUMBONAS {
  height: auto;
  font-weight: 700;
  color: #fff;
  background-color: #e49c00;
  border-radius: 10px;
  line-height: 30px;
  margin-left: auto;
  margin-right: auto;
}

.lockerOpeningID {
  width: 60px;
  height: auto;
  font-weight: 700;
  border-radius: 10px;
  line-height: 30px;
  margin-left: auto;
  margin-right: auto;
}

.faultBox {
  width: 16%;
  height: auto;
  border-radius: 20px;
  border: 5px solid #ffa600;
  background-color: #00567d;
  padding: 10px;
  color: #fff !important;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1200px) {
  .faultBox {
    width: 49%;
    height: auto;
    border-radius: 20px;
    border: 5px solid #ffa600;
    background-color: #00567d;
    padding: 10px;
    color: #fff !important;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1vw;
  }
}

.faultBoxTotal {
  width: 98%;
  margin-bottom: 1vw;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  border-radius: 20px;
  border: 5px solid #ffa600;
  background-color: #2dccd3;
  padding: 10px;
  color: #fff !important;
  text-align: center;
}

.dragon-gif {
  width: 500px;
  bottom: 0;
  position: fixed;
  z-index: 10;
  left: 50%;
  transform: translateX(-50%);
}

.replacement {
  width: 30%;
}

.replacementTitle {
  width: 30%;
}

.replacementValue {
  width: 30%;
}

.replacementContainer {
  align-items: center;
  justify-content: space-between;
}

/* ARQUEOS CALENDAR */

.calendar-container {
  display: flex;
  flex-wrap: wrap;
}

.calendar-year {
  margin: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
}

.calendar-month {
  margin-bottom: 20px;
  width: 226px;
}

.calendar-day {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: 1px solid #ccc;
  margin: 2px;
}

.has-arqueo {
  background-color: green;
  color: white;
}

.calendar-header {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border: 1px solid #ccc;
  margin: 2px;
  background-color: #f0f0f0;
}

.calendar-year h2 {
  font-size: 20px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.calendar-month h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.calendar-day {
  cursor: pointer;
  transition: background-color 0.2s;
}

.calendar-day:hover {
  background-color: #f0f0f0;
}


/* FORM LOADING WHEEL */

.loading-wheel {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff;
  /* Change the color as desired */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* LOCKER MODAL */

@media (min-width: 1200px) {
  .locker-modal {
    max-width: 50vw !important;
  }

  .locker-modal-admin {
    max-width: 80vw !important;
  }
}


/* RssiBar.css */

/* Styling for the RSSI squares */
.rssi-square {
  width: 10% !important;
  height: 30px !important;
  display: inline-block !important;
  border: 2px solid #4a4a4a !important;
  text-align: center !important;
  vertical-align: middle !important;
  margin-right: 5px !important;
  position: relative !important;
  margin-top: 20px !important;
  border-radius: 0 !important;
}

/* Styling for the highlighted square */
.rssi-square.highlighted {
  color: #000 !important;
  border: 4px solid #f00 !important;
}

.rssi-square h5 {
  font-size: 1rem !important;
}

/* Styling for the RSSI bar container */
.rssi-bar {
  display: flex !important;
  align-items: center !important;
}

/* Define styles for the battery indicator container */
.battery-indicator {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

/* Define styles for the battery info section (percentage and number) */
.battery-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
  /* Adjust spacing as needed */
}

/* Style the battery percentage */
.battery-percentage {
  font-size: 16px;
  /* Adjust font size as needed */
}

/* Style the battery number */
.battery-number {
  font-size: 14px;
  /* Adjust font size as needed */
}

/* Define styles for the battery shape container */
.battery-shape {
  width: 170px;
  height: 28px;
  position: relative;
  border: 5px solid #4d4d4d;
  border-radius: 5px;
  display: flex;
}

/* Define styles for the battery squares inside the shape */
.battery-square {
  height: 16px;
  background-color: #000;
  margin: 1px;
  width: 30px;
}

/* Style the battery colors */
.green {
  background-color: green !important;
}

.orange {
  background-color: orange !important;
}

.red {
  background-color: red !important;
}

.btn-remoteAction {
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

/* ProgressBar.css */
.progress-container {
  position: relative;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  min-width: 100px;
}

.progress-bar {
  height: 20px;
  width: 0;
  border-radius: 8px;
  transition: width 1s ease-in-out;
  /* Animation effect */
}

.progress-percentage {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  /* Center vertically */
  color: #000;
  /* Adjust color as needed */
}

.progress-container-promoter {
  position: relative;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 40px;
  overflow: hidden;
  min-width: 100px;
}

.progress-bar-promoter {
  height: 45px;
  width: 0;
  border-radius: 40px;
  transition: width 1s ease-in-out;
  /* Animation effect */
}

.progress-percentage-promoter {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  /* Center vertically */
  color: #000;
  /* Adjust color as needed */
  font-size: 24pt;
}


/*  STEP BAR */

.step {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
  border: 2px solid #2dccd3;
  color: #2dccd3;
  background-color: #ffffff;
  text-align: center;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
}

.stepActive {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
  border: 2px solid #2dccd3;
  color: #ffffff;
  background-color: #ffc400;
  text-align: center;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
}

.stepOld {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100px;
  border: 2px solid #2dccd3;
  color: #ffffff;
  background-color: #2dccd3;
  text-align: center;
  font-weight: bold;
  margin-right: auto;
  margin-left: auto;
}

.stepline {
  width: 10%;
  height: 2px;
  background-color: #2dccd3;
  text-align: center;
  border-radius: 100px;
  margin-top: 20px;
}

.steplabel {
  text-align: center;
  border-radius: 100px;
  width: 100%;
  color: #00567d;
}

.steplabelActive {
  text-align: center;
  border-radius: 100px;
  width: 100%;
  font-weight: bold;
  font-size: 18px;
  color: #00567d;
}

.stepparent {
  display: block;
  justify-content: center;
  align-items: center;
  width: 16%;
  /* Add any additional styling you want for the parent container */
  /* For example, you can set height and width if needed */
  /* height: 100px; */
  /* width: 200px; */
}

.stepbar {
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 60px;
  justify-content: center;
  display: flex;
}

@media (min-width: 550px) {
  .lockerSaleContainer {
    width: 550px !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.transaction-details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* Creates two columns */
  column-gap: 100px;
}

@media (max-width: 600px) {
  .transaction-details-grid {
    grid-template-columns: 1fr;
    /* Switch to one column on small screens */
    column-gap: 0px;
    /* Optionally adjust the gap to be smaller */
  }
}

.pass-opens-indicator {
  position: absolute;
  background-color: #ff0000;
  border: 1px solid #fff;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}


/* OPERATOR CALENDAR */

.calendar {
  margin: 20px 0;
}

.calendar h5 {
  text-align: center;
  margin-top: 1vw;
  margin-bottom: 5px;
  background-color: antiquewhite;
  padding: 1vw;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-day-header {
  text-align: center;
  font-weight: bold;
  background-color: aliceblue;
}

.calendar-operator-day {
  padding: 10px;
  border: 1px solid #ccc;
  min-height: 60px;
  min-width: 4vw;
  position: relative;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.day-number {
  font-weight: bold;
  margin-bottom: 5px;
}

.with-price {
  background-color: #e0ffe0;
}

.no-price {
  background-color: #ffe0e0;
}

.no-day {
  background-color: transparent;
  border: none;
  pointer-events: none;
  /* To avoid any interaction on empty slots */
}

.price-info {
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.price-entry {
  font-size: 0.9em;
  padding: 3px;
  border-radius: 3px;
  border: 1px solid #3d3d3d;
  cursor: pointer;
  text-align: center;
}

.price-entry:hover {
  border: 1px solid #2dccd3;
}

.price-buttons button {
  margin-left: 5px;
}

/* BAQUEIRA MATRIX */

.fade-enter {
  opacity: 0;
  transform: translateX(20px);
}

.fade-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.fade-exit {
  opacity: 1;
  transform: translateX(0);
}

.fade-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 1ms, transform 1ms;
}

.white-list-advice {
  border: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}

/* TEMPORADA */

.btn-season {
  height: 5vw;
  border-radius: 10px;
  border: 2px solid #292929;
  background-color: #ccc;
}

.btn-season-selected {
  min-height: 10vw;
  border-radius: 10px;
  border: 2px solid #292929;
  background-color: #ffc400;
}

.btn-baqueira-blocked {
  opacity: 0.2;
}

/* This will prevent the hover effect if the .blocked class is present */
.btn-baqueira-blocked:hover {
  cursor: not-allowed !important; /* Optionally change the cursor to indicate the locker is blocked */
}

/* LOCKER TIPES LEGEND */

.pax2 {
  width: 100%;
  height: 20px;
  border: 1px solid #000;
  background: linear-gradient(180deg, rgba(112, 112, 112, 1) 0%, rgba(179, 179, 179, 1) 100%);
}

.pax3 {
  width: 100%;
  height: 20px;
  border: 1px solid #000;
  background: linear-gradient(180deg, rgba(125, 8, 0, 1) 0%, rgb(201, 10, 3) 100%);
}

.pax4 {
  width: 100%;
  height: 20px;
  border: 1px solid #000;
  background: linear-gradient(180deg, rgba(3, 3, 3, 1) 0%, rgb(43, 43, 43) 100%);
}

.btn-fault {
  border-radius: 10px;
  border: 2px solid #292929;
}

.btn-fault-selected {
  border-radius: 10px;
  border: 2px solid #292929;
  background-color: #ff0000;
}

.btn-fault img{
  width: auto;
  height: 100px;
  text-align: center;
}

.btn-repair-selected {
  border-radius: 10px;
  border: 2px solid #292929;
  background-color: #24b300;
}

.whitelist-btn {
  width: 130px;
  margin-top: 2vw;
  margin-bottom: 2vw;
  min-height: 10vw;
  text-wrap: auto;
  border-radius: 10px;
  border: 2px solid #292929;
}
